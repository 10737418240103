import React, {
    useState,
    useContext, useEffect,
} from 'react'
import { navigate } from "gatsby"
import {
    Page,
    Layout,
    LegacyCard,
    Icon,
    LegacyStack,
    ButtonGroup,
    Button,
    TextContainer,
    Text,
    FormLayout,
} from "@shopify/polaris"
import { ExternalSmallIcon, QuestionCircleIcon } from "@shopify/polaris-icons";
import FirebaseContext from '../../../providers/firebase';
import styled from 'styled-components'
import CreateOfferSteps from './createOfferSteps'
import getMainThemeId from "../../../helpers/getMainThemeId";
import CartUpsellAddSection from '../../../images/CartUpsellAddSection.gif';
const CartOfferPreviewWrapper = styled.div`
    padding: 50px 0 10px 0;
    .preview-button {
        padding: 25px 0 15px 0;
        .Polaris-Button {
            width: 485px;
        }
    }
    .instructions {
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        max-width: 575px;
        margin: auto;
        .instructions-image {
            margin-bottom: 20px;
            padding: 10px;
            border: 1px solid #AAAAAA;
            border-radius: 8px;
            background: #F8F8F8;
            div {
                border: 1px solid #AAAAAA;
                border-radius: 8px;
                img {
                    max-width: 550px;
                }
            }
        }
    }
    .action-buttons {
        padding: 15px 0 25px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
`
const ButtonsWrapper = styled.div`
    margin-top: 15px;
    .Polaris-ButtonGroup__Item:first-child {
        button {
            color: #008060;
            svg {
                fill: #008060;
            }
        }
    }
`
function CartOfferPreview(props) {
    const { shop, token, host } = useContext(FirebaseContext);
    const [active, setActive] = useState(false);
    const [mainThemeId, setMainThemeId] = useState(null);

    const getThemeId = async () => {
        try {
            const fetchedThemeId = await getMainThemeId(token, shop, host);
            console.log('fetchedThemeId in CartOfferPreview: ', fetchedThemeId)
            if (fetchedThemeId?.data?.mainThemeId) {
                setMainThemeId(fetchedThemeId.data.mainThemeId);
            }
        } catch (err) {
            console.log('Error in set main theme id: ', err);
        }
    }

    useEffect(() => {
        getThemeId().catch((err) => console.log('Error in fetch main theme id: ', err));
    }, []);

    return (
      <Page backAction={{
                content: 'Offers',
                onAction: () => {
                    navigate("/app/offers")
                }}} 
                title="Create Offer">
          <Layout>
              <Layout.Section>
                {/*
                  <div style={{margin: '20px 0 10px -25px'}}>
                      <CreateOfferSteps step={3}/>
                  </div>
                */}
                  <LegacyCard>
                      <LegacyCard.Section>
                          <CartOfferPreviewWrapper>
                              <div className='instructions'>
                                  <div className="instructions-image">
                                      <div>
                                          <img src={CartUpsellAddSection} alt={'CartUpsellAddSection'}/>
                                      </div>
                                  </div>
                                  <FormLayout>
                                      <TextContainer spacing="loose">
                                          <Text variant="headingLg" as="p">
                                              Last step! Add your offer to the
                                              <Text as='strong'> app section </Text>
                                              of your cart page
                                          </Text>
                                          <div>
                                              <Text tone="subdued">Add the UpsellPlus theme extension to the cart app section with the theme editor</Text>
                                          </div>
                                      </TextContainer>
                                  </FormLayout>
                              </div>
                              <div className='action-buttons'>
                                  <div style={{textAlign:'center'}}>
                                      <Button primary={true} onClick={
                                          () => {
                                              setActive(true)
                                              window.open(`https://${shop}/admin/themes/${mainThemeId}/editor?previewPath=%2Fcart`, '_blank')
                                          }
                                      }>
                                          <LegacyStack distribution="center" alignment='center' spacing='tight'>
                                              <span>Go to theme editor</span>
                                              <Icon
                                                source={ExternalSmallIcon}
                                                tone="base"
                                              />
                                          </LegacyStack>
                                      </Button>
                                  </div>
                                  <ButtonsWrapper>
                                      <LegacyStack distribution="center">
                                          <ButtonGroup>
                                              <Button plain onClick={
                                                  () => {
                                                      if(window && window.$crisp){
                                                          window.$crisp.push(["do", "chat:open"]);
                                                      }
                                                  }
                                              }>
                                                  <LegacyStack distribution="center" alignment='center' spacing='tight'>
                                                      <Icon
                                                        source={QuestionCircleIcon}
                                                        tone="base"
                                                      />
                                                      <span>Get help</span>
                                                  </LegacyStack>
                                              </Button>
                                              {active ?
                                                <Button primary={true} onClick={
                                                    () => {
                                                        navigate('/app/offers', { replace: true,})
                                                    }
                                                }>Mark as done</Button>
                                                :
                                                <Button primary disabled>
                                                    Mark as done
                                                </Button>
                                              }
                                          </ButtonGroup>
                                      </LegacyStack>
                                  </ButtonsWrapper>
                              </div>
                          </CartOfferPreviewWrapper>
                      </LegacyCard.Section>
                  </LegacyCard>
              </Layout.Section>
          </Layout>
      </Page>
    )
}

export default CartOfferPreview;